<template>
    <div>
        <!--AxBasImagesList.vue
        {
            pageFlag: true,
            id: "ax_bas_images",
            title: "图片库",
            name: "ax_bas_images",
            icon: "el-icon-document",
            component: "views/chanjet/AxBasImagesList.vue",
            componentdetail: "views/chanjet/AxBasImagesDetail.vue",
            visible: true
        }
        -->
        <el-container>
            <el-main>
                <el-button-group>
                    <el-button type="" size="mini" @click="syslistquery">查询</el-button>
                    <el-button type="" size="mini" @click="syslistadd">新增</el-button>
                </el-button-group>
                <vxe-form size="mini">
                    <vxe-form-item title="名称" field="name" :item-render="{}" style="width:60px;">
                        <vxe-input v-model="p.name" size="mini" placeholder="名称"></vxe-input>
                    </vxe-form-item>
                </vxe-form>
                <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow highlight-hover-row size="mini" :data="rs" :height="meta.h">
                    <vxe-table-column field="guid" title="guid" :visible ="false"  width="180"></vxe-table-column>
                    <vxe-table-column field="code" title="编码" width="250"></vxe-table-column>
                    <vxe-table-column field="name" title="名称" width="250"></vxe-table-column>
                    <vxe-table-column field="url" title="链接" width="500" :visible ="false"></vxe-table-column>
                    <vxe-table-column field="vtype" title="类型" width="50" :visible="false"></vxe-table-column>
                    <vxe-table-column field="createuser" title="新增人" width="150"></vxe-table-column>
                    <vxe-table-column field="updateuser" title="修改人" width="150"></vxe-table-column>
                    <vxe-table-column field="remark" title="备注" width="500"></vxe-table-column>
                    <vxe-table-column fixed="right" width="120" title="操作">
                        <template #default="{row, rowIndex }">
                            <a @click="syslistdeleteone(row)">删除 | </a>
                            <a @click="syslistqueryone(row)">详细</a>
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <vxe-pager :align="'left'" background :current-page.sync="p1.page.current" :page-size.sync="p1.page.size" :total="p1.page.total" @page-change="pagechange" :page-sizes=[100,500,1000,10000] :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
                </vxe-pager>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";
    export default {
        name: "ax_bas_imageslist",
        extends: ax.control1(),
        data() {
            return {
                p: { name: "" },
                model: "chanjet",
                settings: "axBasImages",
                vouchertype: "list",
            }
        },
    }
</script>